import SlaCreatePage from '@/views/SlaCreate/index.vue'

export default {
  name: 'SlaPage',
  components: {
    SlaCreatePage,
  },
  data() {
    return {
      // sla列表数据
      slaList: [],
      // 翻页配置
      pagesObj: {
        page: 1,
        pagesize: 10,
        total: 0
      },
      // 搜索条件
      query: '',
      // 创建弹窗
      dialogVisible: false,
      // 编辑数据
      editData: null,
    }
  },
  mounted() {
    this.getSLAList()

  },
  methods: {
    // 获取sla列表
    getSLAList(page) {
      if (page) {
        this.pagesObj.page = page
      } else {
        this.pagesObj.page = 1
      }
      this.$http.post('/v2/common/slapriority/list', {
        hrid: this.$store.getters.loginid,
        penum: this.$store.getters.penum,
        "query": this.query,
        page: this.pagesObj.page,
        pagesize: this.pagesObj.pagesize,
      }, {
        loadTarget: this.$refs.tableWrapper
      }).then(response => {
        // 更新数据
        if (response.data.success) {
          this.slaList = response.data.content.list;
          if (Array.isArray(this.slaList) && !this.slaList.length && this.pagesObj.page > 1) {
            this.getSLAList(this.pagesObj.page - 1)
            return
          }
          this.pagesObj = {
            ...this.pagesObj,
            total: response.data.content.page.total
          }

        } else {
          this.$alert(response.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      })
    },
    // 删除
    deleteClick(row) {
      this.$utils.blurFn()
      this.$confirm(this.$t('securityRules.confirmDelete'), this.$t('common.alertPrompt'), {
        type: 'warning',
        center: true
      }).then(() => {

        const requsetJson = {
          hrid: this.$store.getters.loginid,
          id: row.id,
          feedbacktime: row.feedbacktime
        }
        this.$http.post('/v2/common/slapriority/delete', requsetJson).then(response => {
          if (response.data.success) {
            this.$message({
              type: 'success',
              message: this.$t('sla.deleteSuccess')
            });
            // 更新组列表
            this.getSLAList(this.pagesObj.page);
          } else {
            this.$alert(response.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true
            })
          }
        })
      }).catch(() => {

      });
    },
    // 创建
    createAuthority() {
      this.editData = null
      this.dialogVisible = true
    },
    // 创建或修改成功
    createSuccess() {
      this.dialogVisible = false
      this.getSLAList()
    },
    // 关闭弹窗
    closeDialog() {
      this.dialogVisible = false
    },
    // 修改
    editClick(item) {
      this.editData = item
      this.dialogVisible = true

    },
    // 搜索
    searchEvent() {
      this.getSLAList()
    },
    
  }
}